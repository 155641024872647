import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import '../css/lifeBalance.css';

const LifeBalance = () => {

    const {userYear} = useParams()
    const now = new Date()
    const currentDay = Math.ceil((now - new Date(now.getFullYear(),0,1)) / 86400000)
    const [dayOfTheYear, setDayOfTheYear] = useState(currentDay)
    const thisYear = now.getFullYear()
    const thisMonth = now.getMonth()
    const thisDay = now.getDate()
    const currentMonth = thisYear + (thisMonth/100)
    const [searchYear, setSearchYear] = useState( userYear ? userYear*1 : thisYear ) 
    const [data, setData] = useState([])
    const [totalWork, setTotalW] = useState(0)
    const [totalWeekTrvl, setTotalWeekT] = useState(0)
    const [totalWeekendTrvl, setWeekendT] = useState(0)
    const [totalWeekFree, setWeekF] = useState(0)
    const [totalWeekendFree, setWeekendF] = useState(0)
    const [showWeekends, setShowWeekends] = useState(true)
    const [showDays, setShowDays] = useState(true)
    const [showMonths, setShowMonths] = useState(false)
    const [showYears, setShowYears] = useState(false)
    const [totalData, setTotalData] = useState(false)
    const [summary, setSummary] = useState([])
    const dayBoxColors = ['#ffffff33','var(--colWork)','var(--colTravel)'] // 0:Free, 1:Worked, 2:Travel ['#ffffff33','#ffa500cc','#adff2fcc']
    const dayTextColors = ['#5555ff','white','green','#0000ff88','red','green'] // 0:Free, 1:Worked, 2:Travel
    const url = "https://raw.githubusercontent.com/victorverdugo/life-balance/refs/heads/main/balance.json"
    const [sumaryIntroTxt, setSumaryIntroTxt] = useState('He trabajado')
    const [sumaryOutroTxt, setSumaryOutroTxt] = useState(' días que van hasta hoy.')
    const [print, setPrint] = useState(false)
    const [lastRun, setLastRun] = useState(0)


    useEffect(() => {
        fetch(url)
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            // console.log(data);
            setData(data)
        })
        .catch((err) => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        if(data[searchYear]!== undefined){
        let weekWorked=0
        let weekFree=0
        let weekendFree=0
        let weekTravel=0
        let weekendTravel=0
        let mIndex =0
        let summaryTemp = [{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},{"w":0,'t':0,'ww':0,'wt':0,'f':0,'wf':0},]
        data[searchYear].map((m) => {
            m.i.map((i,index)=> {
                const whatDay = (new Date(searchYear,mIndex,index+1)).getDay()
                if((now - new Date(searchYear,mIndex,index+1)) / 86400000 > 0){//Only calculate on days before today.
                    if(i[0]===1){
                        weekWorked=weekWorked+1
                        summaryTemp[mIndex]['w']=summaryTemp[mIndex]['w']+1
                    }
                    if(i[0]===0 && whatDay===0){
                        weekendFree = weekendFree+1 // Libre Domingo
                        summaryTemp[mIndex]['wf']=summaryTemp[mIndex]['wf']+1
                    }else if(i[0]===0 && whatDay===6){
                        weekendFree = weekendFree+1 // Libre Sabado
                        summaryTemp[mIndex]['wf']=summaryTemp[mIndex]['wf']+1
                    }else if(i[0]===0 && whatDay!==0){
                        weekFree = weekFree+1 // Libre No-Domingo
                        summaryTemp[mIndex]['f']=summaryTemp[mIndex]['f']+1
                    }else if(i[0]===0 && whatDay!==6){ 
                        weekFree = weekFree+1 // Libre No-Sabado
                        summaryTemp[mIndex]['f']=summaryTemp[mIndex]['f']+1
                    }else if(i[0]===2 && whatDay===0){ 
                        weekendTravel = weekendTravel+1 // Viaje Domingo
                        summaryTemp[mIndex]['wt']=summaryTemp[mIndex]['wt']+1
                    }else if(i[0]===2 && whatDay===6){
                        weekendTravel = weekendTravel+1 // Viaje Sabado
                        summaryTemp[mIndex]['wt']=summaryTemp[mIndex]['wt']+1
                    }else if(i[0]===2 && whatDay!==0){
                        weekTravel = weekTravel+1 // Viaje No-Domingo
                        summaryTemp[mIndex]['t']=summaryTemp[mIndex]['t']+1
                    }else if(i[0]===2 && whatDay!==6){ 
                        weekTravel = weekTravel+1 // Viaje No-Sabado
                        summaryTemp[mIndex]['t']=summaryTemp[mIndex]['t']+1
                    }
                }
             })
            mIndex=mIndex+1
        }    
        )
        document.querySelector('#daysButton').setAttribute("style", `background-color:${dayBoxColors[2]}`)
        setSummary(summaryTemp)
        setTotalW(weekWorked)
        setWeekF(weekFree)
        setTotalWeekT(weekTravel)
        setWeekendF(weekendFree)
        setWeekendT(weekendTravel)
        setTotalData(true)
        }
    }, [data,searchYear])

    //Update progressbar
    useEffect(() => {
        if(searchYear<thisYear){
            document.querySelector('.progressBar').setAttribute("style", `width: ${ 100 }%; transform: translateX(0)`)
        }else if(searchYear>thisYear){
            document.querySelector('.progressBar').setAttribute("style", `width: ${ 0 }%; transform: translateX(0)`)
        }else if(searchYear===thisYear){
            document.querySelector('.progressBar').setAttribute("style", `width: ${ currentDay*100/365 }%; transform: translateX(0)`)
        }
        
    }, [searchYear])
    
    const changeYear = (shiftYear) =>{
        
        if(showYears===false){
            let newYear = Math.min(Math.max(2022,searchYear+shiftYear),thisYear+1)
            setSearchYear(newYear)
            if(newYear===thisYear+1){
                document.querySelector('#buttonUp').setAttribute("style", `width: 10px;color:pink`)
                document.querySelector('#buttonDown').setAttribute("style", `width: 50px;`)
            }else if(newYear<2023){
                document.querySelector('#buttonUp').setAttribute("style", `width: 50px;`)
                document.querySelector('#buttonDown').setAttribute("style", `width: 10px;color:pink`)
            }else{
                document.querySelector('#buttonUp').setAttribute("style", `width: 50px;`)
                document.querySelector('#buttonDown').setAttribute("style", `width: 50px;`) 
            }
    
            if(newYear<thisYear){
                setSumaryIntroTxt(`El ${newYear} trabajé`)
                setSumaryOutroTxt(` días.`)
                document.querySelector('#sumary').setAttribute("style", `opacity: 1;`)
            }else if(newYear==thisYear){
                setSumaryIntroTxt('Este año he trabajado')
                setSumaryOutroTxt(` días que van de este año.`)
                document.querySelector('#sumary').setAttribute("style", `opacity: 1;`)
            }else if(newYear>thisYear){
                document.querySelector('#sumary').setAttribute("style", `opacity: 0;`) 
            }
        }
        
        
    }
    const hideWeekends = () => {
        if(searchYear==thisYear){
            if(showWeekends==true){
                setSumaryOutroTxt(` días hábiles hasta hoy.`)
            }else if(showWeekends==false){
                setSumaryOutroTxt(` días que van hasta hoy.`)
            }
        }else if(searchYear<thisYear){
            if(showWeekends==true){
                setSumaryOutroTxt(` de los días hábiles.`)
            }else if(showWeekends==false){
                setSumaryOutroTxt(` días.`)
            }
        }
        document.querySelector('#weekdaysButton').setAttribute("style", `background-color:${showWeekends?dayBoxColors[2]:'pink'}`)
        setShowWeekends(!showWeekends)
       
        
    }
    const switchDays = () => {
        let switchDays = true

        setShowDays(switchDays)
        setShowMonths(false)
        setShowYears(false)

        document.querySelector('#daysButton').setAttribute("style", `background-color:${switchDays?dayBoxColors[2]:'pink'}`)
        document.querySelector('#monthsButton').setAttribute("style", 'background-color: pink')
        document.querySelector('#yearsButton').setAttribute("style", 'background-color: pink')

        document.querySelector('.monthGroup').setAttribute("style", `transform: translateY(0%);transition: all 1.3s ease;`)
        document.querySelector('.dataTotals').setAttribute("style", `transform: translateY(20%);transition: all 1.3s ease;`)
        document.querySelector('.dataYears').setAttribute("style", `transform: translateY(40%);transition: all 1.3s ease;`)

        document.querySelector('#sumary').setAttribute("style", `opacity: 1;`)
        document.querySelector('#buttonUp').setAttribute("style", `width: 50px;`)
        document.querySelector('#buttonDown').setAttribute("style", `width: 50px;`)
    }
    const switchMonths = () => {

        let switchMonths = true

        setShowDays(false)
        setShowMonths(switchMonths)
        setShowYears(false)

        document.querySelector('#daysButton').setAttribute("style", 'background-color: pink')
        document.querySelector('#monthsButton').setAttribute("style", `background-color:${   dayBoxColors[2]}`)
        document.querySelector('#yearsButton').setAttribute("style", 'background-color: pink')
            
        document.querySelector('.monthGroup').setAttribute("style", `transform: translateY(${switchMonths?'-120%':'0%'});transition: all 1.3s ease;`)
        document.querySelector('.dataTotals').setAttribute("style", `transform: translateY(${switchMonths?'-100%':'0%'});transition: all 1.4s ease;`)
        document.querySelector('.dataYears').setAttribute("style", `transform: translateY(${switchMonths?'-80%':'0%'});transition: all all 1.5s ease;`)
        document.querySelector('#sumary').setAttribute("style", `opacity: 1;`)
        document.querySelector('#buttonUp').setAttribute("style", `width: 50px;`)
        document.querySelector('#buttonDown').setAttribute("style", `width: 50px;`)
        

    }
    const switchYears = () => {
        let switchYears = true

        setShowDays(false)
        setShowMonths(false)
        setShowYears(true)

        document.querySelector('#daysButton').setAttribute("style", 'background-color: pink')
        document.querySelector('#monthsButton').setAttribute("style", 'background-color: pink')
        document.querySelector('#yearsButton').setAttribute("style", `background-color:${   dayBoxColors[2]}`)

        document.querySelector('.monthGroup').setAttribute("style", `transform: translateY(${switchYears?'-240%':'0%'});transition: all 1.3s ease;`)
        document.querySelector('.dataTotals').setAttribute("style", `transform: translateY(${switchYears?'-220%':'0%'});transition: all 1.4s ease;`)
        document.querySelector('.dataYears').setAttribute("style", `transform: translateY(${switchYears?'-200%':'0%'});transition: all 1.5s ease;`) 
        document.querySelector('#sumary').setAttribute("style", `opacity: 0;`)
        document.querySelector('#buttonUp').setAttribute("style", `width: 10px;color:pink`)
        document.querySelector('#buttonDown').setAttribute("style", `width: 10px;color:pink`)

    }
    const RenderDivs = ({ monthData, mIndex }) => {

        return (
        <div className="month" key={monthData.m}>
            <div className="monthLable" key={monthData.n}>{monthData.n}</div>
                {monthData.i.map((i, index) =>(
                    <div className="dayDiv" key={index} style={{opacity:(now - new Date(searchYear,mIndex,index+1))>0?1:0.3,backgroundColor: (now - new Date(searchYear,mIndex,index+1))>0?dayBoxColors[i[0]]:'#ffffff33', display: ((new Date(searchYear,mIndex,index+1)).getDay()===0)||((new Date(searchYear,mIndex,index+1)).getDay()===6) ? showWeekends?'flex':'none' : 'flex'}}>
                        <div className="dayNumber" style={{color: (now - new Date(searchYear,mIndex,index+1))<0 ? '#0000ff88' : ((new Date(searchYear,mIndex,index+1)).getDay()===0)||((new Date(searchYear,mIndex,index+1)).getDay()===6) ? dayTextColors[i[0]+3]:dayTextColors[i[0]],display:(now - new Date(searchYear,mIndex,index+1))>0?'flex':'flex',backgroundColor: (now - new Date(searchYear,mIndex,index+1))>0?((new Date(searchYear,mIndex,index+1)).getDay()===0)||((new Date(searchYear,mIndex,index+1)).getDay()===6)?'#ffffff':'#00000000':'#ffffff00'}}>{index+1}</div>
                        <div className="dayInfo" style={{color: dayTextColors[i[0]],display:(now - new Date(searchYear,mIndex,index+1))>0?'flex':'none'}}>{i[1]}</div>
                    </div>
                ))}
            </div>
        )
    }
    
    const RenderTotals = ({ index, monthData }) => {
    //   console.log(monthData[index]['d'])

        return (
            <div className="monthColumn" key={'d'}>
                <div className="monthLable" key={monthData.n}>{monthData[index].n}</div>
                <div className="totalMonthCont" >
                    <div className="totalMonth" style={{backgroundColor: dayBoxColors[2],height: `${showWeekends?(summary[index]['t']+summary[index]['wt'])/monthData[index]['d']*100:summary[index]['t']/(monthData[index]['d']-summary[index]['wt']-summary[index]['wf'])*100}%`,color:dayTextColors[5],justifyContent: 'flex-end'}}><span className="totalSubText">Viajados</span>{showWeekends?summary[index]['t']+summary[index]['wt']:summary[index]['t']}</div>
                    <div className="totalMonth" style={{backgroundColor: dayBoxColors[0],height: `${showWeekends?(summary[index]['f']+summary[index]['wf'])/monthData[index]['d']*100:summary[index]['f']/(monthData[index]['d']-summary[index]['wt']-summary[index]['wf'])*100}%`,color:dayTextColors[3],justifyContent: 'center'}}>{showWeekends?summary[index]['f']+summary[index]['wf']:summary[index]['f']}<span className="totalSubText">Libres</span></div>
                    <div className="totalMonth" style={{backgroundColor: dayBoxColors[1],height: `${showWeekends?summary[index]['w']/monthData[index]['d']*100:summary[index]['w']/(monthData[index]['d']-summary[index]['wt']-summary[index]['wf'])*100}%`,justifyContent: 'flex-start'}} >{summary[index]['w']}<span className="totalSubText">Trabajados</span></div>
                </div>
            </div>)
    };
        const RenderYears = ({i, index, monthData }) => {
            let allYearsTotals = {}
            let yearList = []
            for (let y = 2022; y < thisYear+1; y++) {
                
                yearList.push(y)
                allYearsTotals[y] = {'free':0,'worked':0,'travelled':0}
            }
            
            // Loop through Years from 2022 until now
            for (let y = 2022; y < thisYear+1; y++) {

                //Mapping throught months
                data[y].map((m)=>{

                    //Mapping through days
                    m['i'].map((d)=>{
                        if(d[0]===0){
                            allYearsTotals[y]['free'] += 1 
                        }else if(d[0]===1){
                            allYearsTotals[y]['worked'] += 1 
                        }else if(d[0]===2){
                            allYearsTotals[y]['travelled'] += 1 
                        }
                    })

                })

              }

        
        return (

                <div className="yearsList" style={{width:'100%', height:'100%'}}>
                    {
                    yearList.map((i,index)=>(
                        <div className="monthRow" style={{width:'100%', height:`${100/yearList.length}%`}} key={'d'+i}>
                            <div className="monthLable" key={i} style={{width:'14%'}}>{i}</div>
                            <div className="yearsMonthRow" >
                                <div className="yearsSummary" style={{ backgroundColor: dayBoxColors[1], width:`${(allYearsTotals[i]['worked'])*100/365}%`, display: allYearsTotals[i]['worked']<1?'none':'flex',color:dayTextColors[1] }}>{allYearsTotals[i]['worked']}<span className="totalSubText">Trabajados</span></div>
                                <div className="yearsSummary" style={{ backgroundColor: dayBoxColors[0], width:`${(allYearsTotals[i]['free'])*100/365}%`,color:dayTextColors[3] }}>{allYearsTotals[i]['free']}<span className="totalSubText">Libres</span></div>
                                <div className="yearsSummary" style={{ backgroundColor: dayBoxColors[2], width:`${(allYearsTotals[i]['travelled'])*100/365}%`, display: allYearsTotals[i]['travelled']<1?'none':'flex',color:dayTextColors[5]  }}>{allYearsTotals[i]['travelled']}<span className="totalSubText">Viajados</span></div>
                            </div>
                        </div>
                    ))
                    }
                    

                </div>
        )
    };
    
    return (
        <div className="pageCont">
                <div className="dataDisplay">
                    <div className="monthGroup">
                        {data[thisYear]!==undefined
                        ?
                        data[searchYear].map((monthData, index) =>
                            <RenderDivs monthData={monthData} mIndex={index}/>
                        )
                        :
                        <div className="yearTitle" style={{width:'100%',height:'100%',display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>LOADING</div>
                        }
                        
                    </div>
                    <div className="dataTotals">
                        { totalData === true
                        ?
                        data[searchYear].map((i, index, monthData) =>
                            <RenderTotals i={i} index={index} monthData={monthData}/>
                        )
                        :
                        null
                        }
                        </div>
                        <div className="dataYears">
                        { totalData === true
                        ?
                        <RenderYears/>
                        :
                        null
                        }
                    </div>
                </div>
                <div className="progressBarCont"><div className="progressBar" style={{ width:`${dayOfTheYear*100/365 }%; transform: translateX(0)`}} key={'bar'}></div></div>
                <div className="bottomGroup">
                    <div className="yearTitle">{showYears?'ALL-YEARS':searchYear}</div>
                    
                    <div className="infogroup">
                        <div className="info" id="sumary">{sumaryIntroTxt} <span className="infoNumber">{totalWork}</span>, viajado <span className="infoNumber">{totalWeekTrvl+(showWeekends?totalWeekendTrvl:0)}</span> y he parado el dedo <span className="infoNumber">{totalWeekFree+(showWeekends?totalWeekendFree:0)}</span> {searchYear===thisYear?'de los ':''}<span className="infoNumber" >{searchYear===thisYear?showWeekends?currentDay:currentDay-totalWeekendFree-totalWeekendTrvl:''}</span>{sumaryOutroTxt}</div>
                    </div>

                    <div className="buttonsGroup">
                        <div className="buttonsHorizGroup">
                            <div className="button weekendSwitch" id="weekdaysButton" onClick={hideWeekends}>Weekdays</div>
                            <div className="button weekendSwitch" id="daysButton" onClick={switchDays}>Days</div>
                            <div className="button weekendSwitch" id="monthsButton" onClick={switchMonths}>Months</div>
                            <div className="button weekendSwitch" id="yearsButton" onClick={switchYears}>Years</div>
                        </div>
                        
                        <div className="button yearButton" id="buttonDown" onClick={()=>{changeYear(-1)}}>&#8592;</div>
                        <div className="button yearButton" id="buttonUp" onClick={()=>{changeYear(1)}}>&#8594;</div>
                    </div>
                </div>
        </div>
  );
}
export default LifeBalance;