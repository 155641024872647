import { useState,useEffect } from "react"
import { Helmet } from 'react-helmet'
import '../css/renderTime.css'


const RenderTime = () => {
    
    const messageList = [
        'Initial frame is lower than final frame.'
    ]

    const [frames, setFrames] = useState(0)
    const [frameIn, setFrameIn] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [print, setPrint] = useState(false)
    const [message, setMessage] = useState('')


    const [resultHrs, setResultHrs] = useState(0)
    const [resultMin, seResulttMin] = useState(0)
    const [resultSec, setResultSec] = useState(0)

    

    useEffect(() => {
        
        let totalFrames = frames - frameIn + 1

        let totalSecs = totalFrames * ((minutes*60)+seconds)

        let tempHrs = Math.floor(totalSecs / 3600)

        let tempMins = Math.floor((totalSecs % 3600) / 60)



        if(totalFrames<0){

            setTimeout(()=>{setPrint(true)}, 2000)
            
            setMessage(messageList[0])
        }else{
            setPrint(false) 
        }

        if(tempMins==60){
            tempMins -= 60
            tempHrs += 1
        }
    
        setResultHrs(tempHrs)
        seResulttMin(tempMins)
        setResultSec(totalSecs%60)

    });
    
        
    return(
        <> 
        <Helmet>
            <title>Render Time</title>
        </Helmet>
        <div className="message" style={{opacity:print?1:0}}>{message}</div>
        <div className="rndrPage" style={{'height':'100vh', backgroundColor:'#e0e0e0'}}>
            
            <div className="footerSignUp">@friendly_bot</div>

            <div className="rndrInputs">
                <div className="inputGroup" style={{backgroundColor:'#222222'}}>
                    <div className="inputLabel">FRAMES</div>
                    <div className="inputGroupFrames" style={{}}>
                        <input type="number"name="frameIn" className="inputForm" onChange={(e)=>setFrameIn(e.target.value*1)} style={{fontSize:frameIn>999?'2.5em':'4em'}} placeholder="IN"/>
                        <div style={{color:'#ffffff',fontSize:'2em',opacity:0.3}}>{'>'}</div>
                        <input type="number"name="frames" className="inputForm" onChange={(e)=>setFrames(e.target.value*1)} style={{fontSize:frames>999?'2.5em':'4em'}} placeholder="TO"/>
                    </div>
                </div>
                <div className="inputGroup">
                    <div className="inputLabel">Minutes</div>
                    <input type="number" name="minutes" className="inputForm" onChange={(e)=>setMinutes(e.target.value*1)} placeholder="0m"/>
                </div>
                <div className="inputGroup">
                    <div className="inputLabel">Seconds</div>
                    <input type="number" name="seconds" className="inputForm" onChange={(e)=>setSeconds(e.target.value*1)} placeholder="0s"/>
                </div>
            </div>
            
            <div className="rndrResult">
                <div className="rndrTime">
                    <div className="resultH resultNumber" style={{display:resultHrs<1?'none':'block'}}>{resultHrs}<span className="resultUnit">h</span></div>
                    <div className="resultM resultNumber" style={{display:resultMin<1?'none':'block'}}><span style={{display:resultMin<9?'inline':'none'}}>0</span>{resultMin}<span className="resultUnit">m</span></div>
                    <div className="resultS resultNumber" style={{display:resultSec<1?'none':'block'}}><span style={{display:resultSec<9?'inline':'none'}}>0</span>{resultSec}<span className="resultUnit">s</span></div>
                </div>
                <div className="underline"></div>
            </div>

            <div className="footerSignDown">@vicver</div>
            
            
        </div>
        </>
    )
}
 
export default RenderTime;